<template>
  <div>
    <v-container
      fluid
      color="white"
      style="height: max-content"
      class="mb-12 hidden-sm-and-down"
    >
      <p
        style="
          color: var(--secondary-light);
          font-weight: 300;
          /* position: absolute; */
          z-index: 0 !important;
        "
        class="lgText sm-ml-2 ml-16 pt-2"
      >
        {{ "services".toUpperCase() }}
      </p>
      <v-row>
        <v-col offset="1" cols="4" style="z-index: 1" class="">
          <p>
            Looking for personalized, non-invasive solutions to achieve your
            health and wellness goals? Look no further than Petty Medical. Our
            range of services includes anti-aging therapy, hormone therapy,
            regenerative therapy, IV therapy, and more. Discover how we can help
            you look and feel your best.
          </p>
        </v-col>
        <v-col cols="8"></v-col>
      </v-row>
      <v-row>
        <v-container class="mt-8" fluid>
          <v-slide-group show-arrows min-width="100vw">
            <v-slide-item v-for="(service, i) in services" :key="i">
              <HomeServiceInfoCard
                :title="service.name"
                :content="service.shortDescription"
                :img-src="service.path"
              />
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </v-row>
    </v-container>

    <!-- mobile view  -->
    <v-container
      class="hidden-md-and-up d-flex-column ma-0 pa-0"
      fluid
      style="height: min-content"
    >
      <p
        class="mt-4"
        style="
          color: var(--secondary-light);
          font-weight: 300;
          font-size: 5rem;
          width: 100%;
          overflow-wrap: break-word !important;
          line-height: 7rem;
          text-align: center;
          z-index: 0 !important;
        "
      >
        SERVICES
      </p>
      <p fixed class="px-4 mt-4" style="font-weight: 300">
        Looking for personalized, non-invasive solutions to achieve your health
        and wellness goals? Look no further than Petty Medical. Our range of
        services includes anti-aging therapy, hormone therapy, regenerative
        therapy, IV therapy, and more. Discover how we can help you look and
        feel your best.
      </p>

      <v-row class="mt-4 mb-8 mx-0">
        <swiper-container
          class="pb-4"
          :centered-slides="true"
          :pagination="true"
          :pagination-clickable="true"
          :pagination-dynamic-bullets="true"
          :pagination-dynamic-main-bullets="3"
          :modules="modules"
          css-mode="true"
        >
          <swiper-slide
            v-for="(service, i) in services"
            :key="i"
            style="width: 100%"
          >
            <v-row class="ma-0 justify-center">
              <HomeServiceInfoCard
                :title="service.name"
                :content="service.shortDescription"
                :img-src="service.path"
              />
            </v-row>
          </swiper-slide>
        </swiper-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HelperFunctions from "../../mixins/HelperFunctions";
import HomeServiceInfoCard from "@/components/Homepage/HomeServiceInfoCard.vue";

import "swiper/css";
import { Pagination } from "swiper";

export default {
  components: {
    HomeServiceInfoCard,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  data: () => ({
    services: HelperFunctions.services,
  }),
};
</script>

<style scoped>
h2 {
  color: var(--secondary);
  opacity: 0.4;
  font-weight: 400;
}

div.v-slide-group__next {
  padding-right: 2rem !important;
}
.title-mobile {
  color: var(--secondary-light);
  font-weight: 300;
  position: absolute;
  font-size: 5rem;
  width: 100%;
  overflow-wrap: break-word !important;
  line-height: 9rem;
  z-index: 0 !important;
}
.v-slide-group__wrapper {
  touch-action: auto;
  overflow: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}
</style>
